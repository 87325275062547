


















































































































































import { Component, Vue, Ref } from 'vue-property-decorator';
import { SweetAlertOptions } from 'sweetalert2';
import { Saque, Api, Auth } from '@/plugins/api';
import { FilePond } from 'filepond';
import ComissoesTable from '@/views/app/Comissoes/components/ComissoesTable.vue';
import CardIndicador from '@/views/app/Indicadores/components/CardIndicador.vue';
import ComprovantesGrid from '@/views/app/Indicadores/components/ComprovantesGrid.vue';

@Component({
  components: {
    CardIndicador,
    ComprovantesGrid,
  },
})
export default class InfoSaque extends Vue {
  @Ref() readonly comissoesTable!: ComissoesTable;
  @Ref() readonly filepond!: FilePond;
  public selectedTab = 0;
  public progressSituacao = false;
  public notificacao = false;
  public optionsNotificacao = [
    { value: true, text: 'Sim' },
    { value: false, text: 'Não' },
  ];

  public loadingSaque = true;
  public saqueId!: number;
  public indicadorId = 0;
  public saque = {} as ISaque;

  public showPopover = false;
  public comprovanteIndex = null;
  public comprovantes: IComprovante[] = [];
  public filePondServer = {};

  created(): void {
    this.loadingSaque = true;
    this.saqueId = parseInt(this.$route.params.id, 10);
    this.updateInfo();

    this.filePondServer = {
      url: Api.baseUrl,
      process: {
        url: `/saques/${this.saqueId}/comprovantes`,
        headers: {
          Authorization: Auth.instance.token,
        },
      },
    };
  }

  updateInfo(): void {
    this.loadingSaque = true;

    Saque.getByID(this.saqueId)
      .then((response: GetSaqueResponse) => {
        this.loadingSaque = false;
        this.saque = response.data;
        this.indicadorId = this.saque.id_indicador;
        if (response.data.comprovantes) {
          this.comprovantes = [response.data.comprovantes];
        } else {
          this.comprovantes = [];
        }
      })
      .catch((err) => {
        this.$router.replace('/saques');
        this.$swal({
          icon: 'error',
          title: err.response.data,
        });
      });
  }

  marcarPago(): void {
    if (this.comprovantes.length === 0) {
      this.showPopover = !this.showPopover;
    } else {
      this.showPopover = false;
      this.marcarSituacao('pago');
    }
  }

  marcarSituacao(situacao: string): void {
    this.progressSituacao = true;
    const params: PatchSaqueRequest = {
      situacao: situacao,
      notificacao: this.notificacao,
    };
    Saque.patch(this.saqueId, params)
      .then((response) => {
        this.comissoesTable.refresh();
        this.updateInfo();
        this.toastSuccessError(response.success, response.email ?? false);
      })
      .catch(() => {
        this.toastSuccessError(false);
      })
      .finally(() => {
        this.progressSituacao = false;
      });
  }

  toastSuccessError(success: boolean, email?: boolean): void {
    let options = {} as SweetAlertOptions;
    if (success) {
      const text = email ?? false ? 'Email enviado para indicador' : undefined;
      options = {
        icon: 'success',
        title: 'Salvo com sucesso!',
        text: text,
      };
    } else {
      options = {
        icon: 'error',
        title: 'Falha ao mudar situação!',
      };
    }
    this.$swal(options);
  }

  uploadedFiles(): void {
    this.updateInfo();
  }
}
